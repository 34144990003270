<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('forms.add_model', {model: $t('models.student')})}}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <!-- ALERT -->
      <b-alert
          v-if="checkValidUntil"
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            Attention!
          </h4>
          <div class="alert-body">
            <p>School payment is expired ({{last_payment.valid_until}}).</p>
            <p>Student cannot be created.</p>
          </div>
        </b-alert>

      <!-- FORM -->
      <validation-observer
        v-else
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmitLicence)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required|max:255"
            :custom-messages="{ required: $t('validators.required') }"

          >
            <b-form-group
              :label="$t('full_name')"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="userData.fullName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Username"
            rules="required|max:100"
            :custom-messages="{ required: $t('validators.required') }"

          >
            <b-form-group
              :label="$t('email_username')"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="userData.username"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="Password"
            rules="required|password|max:30"
            :custom-messages="{ required: $t('validators.required') }"

          >
            <b-form-group
            :label="$t('auth.password')"
              label-for="password"
            >
              <b-input-group>
                <b-form-input
                  id="password"
                  :type="passwordFieldType"
                  v-model="userData.password"
                  :state="getValidationState(validationContext)"
                  trim
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
                          <div class="invalid-feedback mb-1" 
                :style="feedbackStyle($store.getters['app/currentBreakPoint'])">{{ validationContext.errors[0] }}</div>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
          </validation-provider>


          <!-- Language -->
          <validation-provider
            #default="validationContext"
            name="Language"
            rules="required"
            :custom-messages="{ required: $t('validators.required') }"

          >
            <b-form-group
              :label="$t('language')"
              label-for="lang"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.lang"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="langsOptions"
                :clearable="false"
                input-id="lang"
                label="name"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Plan -->
          <!-- <validation-provider
            #default="validationContext"
            name="Plan"
            rules="required"
          >
            <b-form-group
              label="Plan"
              label-for="plan"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.currentPlan"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="planOptions"
                :selectable="val => val.can_select"
                :reduce="val => val.value"
                :clearable="false"
                input-id="plan"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- SUBSCRIPTION -->
          <b-form-group>
            <b-form-checkbox
              v-model="userData.subsSelected"
              plain
            >
           {{$t('free_subscription')}}
            </b-form-checkbox>
          </b-form-group>

            <!-- SUBSCRIPTION DATES -->
          <template v-if="!userData.subsSelected">
            <!-- VALID_FROM -->
            <validation-provider
              #default="validationContext"
              name="valid_from"
            >
            <b-form-group
            :label="$t('fields.valid_from')"
                label-for="valid_from"
                :state="getValidationState(validationContext)"
              >
              <b-form-datepicker
                id="valid_from"
                v-model="now"
                class="mb-1"
                start-weekday="1"
                disabled
                :locale="getLocal"
              />
            </b-form-group>
            </validation-provider>

            <!-- VALID_UNTIL -->
            <validation-provider
              #default="validationContext"
              name="valid_until"
            >
            <b-form-group
                :label="$t('fields.valid_until')"
                label-for="valid_until"
                :state="getValidationState(validationContext)"
              >
              <b-form-datepicker
                id="valid_until"
                v-model="userData.valid_until"
                class="mb-1"
                start-weekday="1"
                :min="minDate"
                :max="last_payment.valid_until"
                :locale="getLocal"
              />
            </b-form-group>
            </validation-provider>

          </template>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('forms.add')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('forms.cancel')}}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { createLicence } from '@/api/routes'
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import {
  BInputGroup, BInputGroupAppend,
  BAlert, BSidebar, BForm, BFormDatepicker, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { max } from 'vee-validate/dist/rules';
import { ref } from '@vue/composition-api'
import { required, alphaNum, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BInputGroup, BInputGroupAppend,
    BAlert, 
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormDatepicker, 
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    langsOptions: {
      type: Array,
      required: true
    },
    school: {
      type: Object,
      required: true
    },
    last_payment: {
      type: Object,
      required: true
    },
  },
  mixins: [togglePasswordVisibility],
  created(){
    extend('required', {
      ...required,
      message: this.$t('forms.errors.required'),
    });
    extend('max', {
      ...max,
      message: (field, { length }) => this.$t('forms.errors.max_characters', { length })
    });
    extend('password', {
      ...password,
      message: this.$t('forms.errors.password'),
    });
  },
  data() {
    const now = new Date()
    const tomorrow = new Date(now)
    tomorrow.setDate(tomorrow.getDate() + 1)


    return {
      required,
      alphaNum,
      password,
      
      now, 
      minDate: tomorrow,
      passwordType: 'password'


    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: '',
      username: '',
      password: '',
      role: 14, //student
      currentPlan: null,
      lang: null,
      max_children: 1,
      subsSelected: false,
      valid_from: '',
      valid_until:''
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods:{
    feedbackStyle(bkpoint){
      return{
        'display': 'block',
        'margin-top': bkpoint === 'lg' || bkpoint === 'xl' || bkpoint === 'md' ? '21px' : '0px',
        'margin-bottom': bkpoint === 'sm' || bkpoint === 'xs' ? '21px' : '0px',
      }
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    validate(){
      if(!this.userData.subsSelected){
        if(this.userData.valid_from > this.userData.valid_until){
          this.makeToast('danger', this.$t('Error'), 'wrong date');
          return false
        }
      }
      return true
    },
    async onSubmitLicence(){
      this.userData['context'] = 'student';
      this.userData['school_id'] = this.school.id;
      let lang = this.userData.lang.id;
      this.userData['lang'] = lang;
      this.userData['currentPlan'] = this.school.payment_method_id;
      this.userData['valid_from'] = this.now.toISOString().slice(0,10);
      
      if(this.validate()){
        await this.$http.post(createLicence, this.userData).then( response => {
          if(response.data.status == 200){
            this.makeToast('success', this.$t('Success'), this.$t('forms.save_success'));
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-user-sidebar-active', false)
          } else {
            this.makeToast('danger', this.$t('Error'), response.data.msg);
          }
        }).catch(error => {
          // console.log("Err -> ", error);
          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
      }
    }
  },
  computed:{
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    getLocal(){
      return this.$store.getters['user/getLang']
    },
    checkValidUntil(){
      if(this.last_payment.valid_until){
        let vu = new Date(this.last_payment.valid_until)
        if(vu > this.now){
          return false
        } else {
          return true
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.password-icon {
  top: 40%;
  right: 47px;
  transform: translateY(-50%);
}
.input-icon {
  position: relative;
}

.input-icon .icon {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
